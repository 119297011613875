@tailwind base;



/* Your own custom base styles */

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Your own custom component styles */

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500&family=Rubik:wght@300;400;500;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500&family=Roboto:wght@300;400;500;700&family=Rubik:wght@300;400;500;700;800&display=swap');

/* figure this out later, probably doing the background the wrong way :( */
.bg-style {
  position: absolute;
  width: 120%;
  height: 120%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: brightness(0.2);
  z-index: -1;
  background-image: url("../assets/images/bg.jpg");
}

@keyframes underlineShimmer {
  0% {
    background-position: 0px 0;
  }

  100% {
    background-position: 100em 0;
  }
}

.animated-underline {
  animation-duration: 5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: underlineShimmer;
  animation-timing-function: linear;
}

body::-webkit-scrollbar {
  display: none;
}